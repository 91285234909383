import React from 'react';
import { ExternalLink } from "react-external-link";
import Gradient from "./Gradient.js";

function ExternalGradientLink(props) {
    return (
        <Gradient dir="left-to-right" from="#363795" to="#1d8fe1">
            <ExternalLink href={props.href}>
                {props.children}
            </ExternalLink>
        </Gradient>
    )

}

export default ExternalGradientLink;
