import React from 'react';
import { useEffect, useState, useCallback } from 'react';

import { API_ROOT } from '../constants.js';

const LOG_PASSWORD = 'infinent';

function Admin() {
    let [logs, setLogs] = useState(null);
    let [viewers, setViewers] = useState(null);
    let [focus, setFocus] = useState(null);
    let [location, setLocation] = useState(null);
    let [counts, setCounts] = useState(null);

    useEffect(() => {
        document.title = 'Anish Kachinthaya | Admin';
        window.scrollTo(0, 0);

        // fetch(`${API_ROOT}/register?page=admin`);

        if (logs == null) {
            fetch(`${API_ROOT}/logs?password=${LOG_PASSWORD}`).then(result => {
            return result.json();
            }).then((data) => {
                if (data) {
                    setLogs(data);
                }
            })
        }

        if (viewers == null) {
            fetch(`${API_ROOT}/viewers?password=${LOG_PASSWORD}`).then(result => {
            return result.json();
            }).then((data) => {
                if (data) {
                    setViewers(data);
                    setFocus(data.data[0]._id);
                }
            })
        }
    });

    useEffect(() => {
        fetch(`${API_ROOT}/locations?password=${LOG_PASSWORD}&ip=${focus}`).then(result => {
            return result.json();
        }).then((data) => {
            if (data) {
                setLocation(data);
            }
        })
    },[focus]);

    useEffect(() => {
        if (viewers == null) {
            return;
        }

        let temp = {};

        viewers.data.map((el, id) => {
            temp[el._id] = Object.keys(el).map(key => {
                let total_count = 0;
                Object.keys(el[key]).map(key2 => {
                    if (el[key][key2].count != null) {
                        total_count += el[key][key2].count;
                    }
                })
                if (el[key].count != null) {
                    total_count += el[key].count;
                }
                return total_count
            }).reduce((partialSum, a) => partialSum + a, 0)
        })

        let temp2 = viewers;

        temp2.data.sort((a, b) => (temp[a._id] > temp[b._id]) ? -1 : 1 );

        setViewers(temp2);
        setCounts(temp);
    },[viewers]);

    
    return (
        <div className="admin-page">
            <div className="viewers-container">
                {viewers ? 
                <div>
                    {viewers.data.map((el, id) => 
                    <div key={id}>
                        <div onClick={() => {setFocus(el._id)}}>
                            <span>{el._id} =&gt; </span>
                            {counts ? <span>{counts[el._id]} clicks </span> : null}
                        </div>
                        <br></br>
                    </div>)}
                </div> : null}
            </div>
            <div className="focus-container">
                <h2>{focus}</h2>
                <br/>
                {viewers ? 
                    <div>
                        <h3>Page Access</h3>
                        {viewers.data.map(element => {
                            if (element._id == focus) {
                                return Object.keys(element).map(key => {
                                    let children = [];
                                    Object.keys(element[key]).map(key2 => {
                                        if (element[key][key2].count != null) {
                                            children.push(<div key={key2}>&emsp;&emsp;/{key}/{key2} {element[key][key2].count} {element[key][key2].referrers ? <div>&emsp;&emsp;&emsp;&emsp;{"Referrers: " + JSON.stringify(element[key][key2].referrers)}</div> : null}</div>);
                                        }
                                    })
                                    if (element[key].count != null) {
                                        return <div key={key}><div>/{key} {element[key].count} {element[key].referrers ? <div>&emsp;&emsp;{'Referrers: ' + JSON.stringify(element[key].referrers)}</div> : null}</div><div>{children}</div></div>
                                    }
                                    return <div key={key}>{children}</div> 
                                });
                            } 
                        })}
                    </div> 
                : null}
                <br/>
                {location ? 
                <div>
                    <h3>Location Data</h3>
                    {Object.keys(location.geo).map(key => {
                        return <div>{key} {location.geo[key]}</div>
                    })}
                </div>
                : null}
            </div>
            <div className="logs-container">
                <table className="logs-table">
                    <tbody>

                    {logs ?
                        logs.logs.map((el, id) => 
                        <tr className="log-item" key={id} onClick={() => {setFocus(el.ip)}}>
                            <td className="log-td">{el._id}</td> 
                            <td className="log-td">{el.ip}</td> 
                            <td className="log-td">{el.page}</td> 
                            <td className="log-td">{el.project || el.article}</td>
                        </tr>)
                    : null}

                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Admin;
