import React from 'react';
import ReactDOM from 'react-dom/client';

import './styles/fonts.css';
import './styles/style.scss';

import { Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";

import Home from './pages/Home.js';
import Redirect from './pages/Redirect.js';
import Admin from './pages/Admin.js';

import { ZOOM, CALENDLY } from './constants.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
          <Route path="*" element={<Home />} />
          
          <Route path='/zoom' element={<Redirect register='zoom' link={ZOOM}/>}/>
          <Route path='/meet' element={<Redirect register='meet' link={CALENDLY}/>}/>

          <Route path='/admin' element={<Admin />}/>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);