import React from 'react';
import { ExternalLink } from "react-external-link";
import { useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faGithub,
  faXTwitter,  // Changed from faTwitter
} from "@fortawesome/free-brands-svg-icons";

import { API_ROOT } from "../constants.js";

import ExternalGradientLink from "./components/ExternalLink.js";
import { motion } from 'framer-motion';

function Home() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1 }
  };

  useEffect(() => {
    document.title = "Anish Kachinthaya";
    window.scrollTo(0, 0);

    if (!document.location.href.includes("test")) {
      fetch(`${API_ROOT}/register?page=home&referrer=${document.referrer}`);
    }
  });

  return (
    <motion.div
    initial="hidden"
    animate="visible"
    variants={containerVariants}>
      <div className="header">
        <div className="header-text-box">
          <motion.div
            variants={itemVariants}
            className="hello-text header-text-home dark">
            Anish Kachinthaya
          </motion.div>

          <p>
            I'm an EECS MS student at UC Berkeley. I recently graduated from{" "}
            <ExternalGradientLink href="https://met.berkeley.edu/">Berkeley MET</ExternalGradientLink>
            {" "}with a BS in EECS and Business.
            I'm excited about data-driven applications, machine learning,
            operating systems, and distributed computing. Within ML, I'm
            interested in multimodal, representation learning, and latent
            spaces.
            <br />
            <br />
            <span className="dark">Previously,</span>
            <ul>
              <motion.li variants={itemVariants}>
                <ExternalGradientLink href="https://www.glean.com/">Glean</ExternalGradientLink>{" "}search index for AI-powered enterprise search
              </motion.li>
              <motion.li variants={itemVariants}>
                <ExternalGradientLink href="https://neo.com/">Neo Scholar</ExternalGradientLink>{" "}and{" "}
                <ExternalGradientLink href="https://fellows.kleinerperkins.com/">KP Fellow</ExternalGradientLink>
              </motion.li>
              <motion.li variants={itemVariants}>
                <ExternalGradientLink href="https://nvidia.com/">NVIDIA</ExternalGradientLink>
                {" "}distributed systems and data governance for{" "}
                <ExternalGradientLink href="https://www.nvidia.com/en-us/data-center/swiftstack/">Swift</ExternalGradientLink>
                {" "}
              </motion.li>
              <motion.li variants={itemVariants}>
                <ExternalGradientLink href="https://palantir.com/">Palantir</ExternalGradientLink>
                {" "}b2b cloud{" "}
                <ExternalGradientLink href="https://www.palantir.com/offerings/fedstart/">FedStart</ExternalGradientLink>
                {" "}offering, built on{" "}
                <ExternalGradientLink href="https://blog.palantir.com/introducing-rubix-kubernetes-at-palantir-ab0ce16ea42e">Rubix</ExternalGradientLink>
              </motion.li>
              <motion.li variants={itemVariants}>
                <ExternalGradientLink href="https://ml.berkeley.edu/">Machine Learning at Berkeley</ExternalGradientLink>
                {" "}VP of industry projects and consulting
              </motion.li>
              <motion.li variants={itemVariants}>
                <ExternalGradientLink href="https://calhacks.io/">Cal Hacks</ExternalGradientLink>
                {" "}director @ hello:world, 8.0, and 9.0
              </motion.li>
              <motion.li variants={itemVariants}>
                <ExternalGradientLink href="https://www.lucidmotors.com/">Lucid Motors</ExternalGradientLink>
                {" "}perception and auto-labeling pipelines
              </motion.li>
              <motion.li variants={itemVariants}>
                <ExternalGradientLink href="https://cs61c.org/fa22/">CS 61C</ExternalGradientLink>
                {" "}tutor in computer architecture and systems
              </motion.li>
              <motion.li variants={itemVariants}>
                <ExternalGradientLink href="https://llnl.gov/">LLNL</ExternalGradientLink>
                {" "}data pipelines for nuclear film analysis
              </motion.li>
            </ul>
          </p>

          <p className="media-links">
            <ExternalLink
              href="https://x.com/anishk__"
              data-umami-event="Home Twitter"
            >
              <FontAwesomeIcon icon={faXTwitter} />
            </ExternalLink>

            <ExternalLink
              href="https://www.linkedin.com/in/anish-kachinthaya/"
              data-umami-event="Home LinkedIn"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </ExternalLink>

            <ExternalLink
              href="https://github.com/anishk23733/"
              data-umami-event="Home Github"
            >
              <FontAwesomeIcon icon={faGithub} />
            </ExternalLink>
          </p>
        </div>
      </div>
    </motion.div>
  );
}

export default Home;
